"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var onload = require('on-load');

var morph = require('nanomorph');

var util = require('@nx-js/observer-util');

var events = require('events');

var observable = util.observable,
    observe = util.observe,
    unobserve = util.unobserve,
    isObservable = util.isObservable;
var KEY_ATTR = 'data-' + onload.KEY_ID;
var id = 1;

function morphable(view) {
  if (typeof view !== 'function') return observable(view);
  var self = this;
  var cache = new WeakMap();
  var reactions = new WeakMap();
  var int = 1;

  var fn = function fn() {
    var args = Array.from(arguments);
    var rawArgs = args.map(function (state) {
      return morphable.raw(state);
    });
    var index = args[0];
    var element;

    if (cache.has(index)) {
      element = cache.get(index);
    } else {
      element = view.apply(self, rawArgs);
      element.id = id++;
    }

    return onload(element, function (el) {
      if (isObservable(index)) {
        if (reactions.has(index)) return;
        cache.set(index, el);
      }

      var init = false;
      var int = el.id;
      var reaction = observe(function () {
        fn.emit.apply(fn, ['premorph', morphable.raw(self), el].concat(_toConsumableArray(rawArgs)));
        var update = view.apply(self, args);
        update.id = update.id || int;
        update.setAttribute(KEY_ATTR, (cache.get(index) || el).getAttribute(KEY_ATTR));
        morph(el, update);

        if (init) {
          fn.emit.apply(fn, ['morph', morphable.raw(self), el].concat(_toConsumableArray(rawArgs)));
        } else {
          fn.emit.apply(fn, ['load', morphable.raw(self), el].concat(_toConsumableArray(rawArgs)));
          init = true;
        }
      });
      if (isObservable(index)) reactions.set(index, reaction);
    }, function (el) {
      if (!reactions.has(index)) return;
      fn.emit.apply(fn, ['unload', morphable.raw(self), el].concat(_toConsumableArray(rawArgs)));
      unobserve(reactions.get(index));
      reactions.delete(index);
    }, id);
  };

  events.call(fn);
  Object.assign(fn, events.prototype);
  return fn;
}

Object.assign(morphable, util);
module.exports = morphable;
