const html = require('bel')
const raw = require('bel/raw')
const _ = require('morphable')
const css = require('sheetify')

const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true
})
const mk = require('@traPtitech/markdown-it-katex')
md.use(mk, {"throwOnError" : false, "errorColor" : " #cc0000"});

// state
const things = _({ main: 'Loading...', footer: '' })

// actions
things.setmain = main => things.main = main
things.setfooter = footer => things.footer = footer

// views
const body = _(things => html`
  <body class=${style}>
    <main>
      ${raw(things.main)}
    </main>
    <footer>
      <span id="source">${things.footer ? html`Source: <a href="${things.footer}">${things.footer}</a>` : ``}</span>
      <span id="logo">${raw(md.renderInline(`[$\\TeX.\\text{ink}$](/)`))}</span>
    </footer>
  </body>
`)

// mount
document.body = body(things)

if (window.location.pathname == undefined || window.location.pathname == `/`) {
  window.location.pathname = '/5b63f1e7717eb819f50205de4e0f599f'
}

var p = window.location.pathname.split('/').filter(x=>x)
var id = p[p.length-1]

fetch(`https://api.github.com/gists/${id}`).then(res =>
  res.json().then(data => {
    const files = data.files
    const keys = Object.keys(files)
    document.title = keys[0]
    things.setmain(md.render(files[keys[0]].content))
    things.setfooter(data['html_url'])
  })
)

// style
const style = css`
  :host {
    background: #f3f6f7;
    margin: 0;
  }

  main, footer {
    width: 65vw;
    margin: 0 auto 1em;
  }

  main {
    background: #fff;
    padding: 2em;
    margin: 1em auto;
  }

  footer {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width:990px) {
    main, footer {
      width: 85vw;
      margin: 0 auto 1em;
    }
    footer {
      flex-wrap: wrap;
      text-align: center;
    }
    #source, #logo {
      display: block;
      padding: 1em 0;
      flex-basis: 100%;
      width: 100%;
    }
  }

  #logo a {
    color: black;
  }

  a {
    color: blue;
  }
`
